input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  width: 1440px;
  height: 1024px;
  margin: 0 auto;
  background: #f1efe7;
  overflow: hidden;
}

.rectangle {
  position: relative;
  width: 1344px;
  height: 913px;
  margin: 56px 0 0 48px;
  background: #ffffff;
  z-index: 1;
  overflow: visible auto;
}
.flex-row-a {
  position: relative;
  width: 1238px;
  height: 60px;
  margin: 15px 0 0 56px;
  z-index: 15;
}
.occupation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 50px;
  top: 9px;
  left: 879px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 9;
}
.full-name {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 9px;
  left: 0;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 15;
  overflow: hidden;
}

.house-type-preference {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 9px;
  left: 419px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 8;
}
.flex-row-e {
  position: relative;
  width: 1238px;
  height: 50px;
  margin: 0 0 0 56px;
  z-index: 22;
}
.survey-name-input {
  position: absolute;
  width: 360px;
  height: 50px;
  top: 0;
  left: 0;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.rectangle-2 {
  position: absolute;
  width: 360px;
  height: 50px;
  top: 0;
  left: 426px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.rectangle-3 {
  position: absolute;
  width: 359px;
  height: 50px;
  top: 0;
  left: 879px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}
.flex-row-ec {
  position: relative;
  width: 1238px;
  height: 53px;
  margin: 28px 0 0 56px;
  z-index: 18;
}
.move-in-date {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 0;
  left: 419px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 7;
}
.upload-profile-picture {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 0;
  left: 879px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 18;
}
.desired-city {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 2px;
  left: 0;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  text-overflow: initial;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 16;
  overflow: hidden;
}

.flex-row-f {
  position: relative;
  width: 927px;
  height: 50px;
  margin: 7px 0 0 56px;
  z-index: 28;
}

.rectangle-4 {
  position: absolute;
  width: 359px;
  height: 50px;
  top: 0;
  left: 0;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.rectangle-5 {
  position: absolute;
  width: 359px;
  height: 50px;
  top: 0;
  left: 426px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.upload {
  position: absolute;
  width: 200px;
  height: 48px;
  top: 0;
  left: 879px;
  z-index: 28;
  overflow: hidden;
}

.icon {
  position: relative;
  width: 40px;
  height: 40px;
  margin: 4px 0 0 4px;
  background-size: 100% 100%;
  z-index: 29;
}

.flex-row-f-6 {
  position: relative;
  width: 1238px;
  height: 56px;
  margin: 23px 0 0 56px;
  z-index: 19;
}

.desired-state {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 0;
  left: 0;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 19;
}

.what-time-sleep {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 5px;
  left: 419px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 17;
}

.bio {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 50px;
  top: 6px;
  left: 879px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 10;
}
.flex-row-da {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 1238px;
  height: 50px;
  margin: 8px 0 0 56px;
  z-index: 25;
}
.rectangle-7 {
  flex-shrink: 0;
  position: relative;
  width: 359px;
  height: 50px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}
.rectangle-8 {
  flex-shrink: 0;
  position: relative;
  width: 359px;
  height: 50px;
  background-size: cover;
  z-index: 25;
}
.flex-row {
  position: relative;
  width: 1238px;
  height: 55px;
  margin: 13px 0 0 56px;
  z-index: 14;
}
.is-smoking-ok {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 0;
  left: 419px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 13;
}
.budget {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 4px;
  left: 0;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 14;
}
.bio-9 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 50px;
  top: 4px;
  left: 879px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 2;
}
.rectangle-a {
  position: relative;
  width: 359px;
  height: 50px;
  margin: 5px 0 0 935px;
  background-size: cover;
  z-index: 26;
}
.flex-row-ebd {
  position: relative;
  width: 826px;
  height: 57px;
  margin: 19px 0 0 56px;
  z-index: 12;
}
.how-often-guests {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 0;
  left: 419px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
}
.are-pets-ok {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 51px;
  top: 6px;
  left: 0;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 12;
}
.flex-row-c {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 826px;
  height: 51px;
  margin: 80px 0 0 56px;
  z-index: 11;
}
.lease-duration {
  flex-shrink: 0;
  position: relative;
  height: 51px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 11;
}
.how-tidy {
  flex-shrink: 0;
  position: relative;
  height: 51px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 5;
}
.flex-row-b {
  position: relative;
  width: 359px;
  height: 51px;
  margin: 82px 0 0 935px;
  z-index: 6;
  overflow: visible auto;
}
.rectangle-c {
  position: relative;
  width: 359px;
  height: 50px;
  margin: 1px 0 0 0;
  background-size: cover;
  z-index: 4;
}
.submit-questionnaire {
  position: absolute;
  width: 359px;
  height: 51px;
  top: 0;
  left: 0;
  cursor: pointer;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  white-space: nowrap;
  letter-spacing: 0.1px;
  background: transparent;
  border: none;
  z-index: 6;
}
.rectangle-d {
  position: absolute;
  width: 1537px;
  height: 1024px;
  top: 0;
  left: -25px;
  background-size: cover;
}

.submit-button {
  position: relative;
  width: 350px;
  height: 50px;
  top: 0;
  left: 66.6%;
  margin: 60px 0 0 36px;
  cursor: pointer;
  background: #cab3a7;
  border: 1px solid #000000;
  z-index: 3;
  box-sizing: content-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.submit-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  width: 66px;
  height: 50px;
  top: 1px;
  left: 150px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 4;
}
