/* src/components/SwipeableCard.css */
.swipeable-card {
  position: absolute;
  will-change: transform;
}

.card {
  background-size: cover;
  background-position: center;
  width: 600px;
  max-width: 85vw;
  height: 500px;
  border-radius: 20px;
  box-shadow: 0px 18px 53px -15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.card-info {
  position: absolute;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
}

.card-info h3 {
  margin: 0;
}

.card-info p {
  margin-top: 5px;
  color: #555;
}
