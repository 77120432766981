.navbar {
    background-color: #333;
    padding: 10px;
  }
  
  .nav-list {
    display: flex;
    justify-content: space-around;
    list-style: none;
  }
  
  .nav-list li a {
    color: white;
    text-decoration: none;
  }
  
  .nav-list li a:hover {
    text-decoration: underline;
  }
  