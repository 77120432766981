/* src/pages/RoommateFinder.css */
.roommate-finder {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.card-container {
  width: 90vw;
  max-width: 600px;
  height: 500px;
}

.infoText {
  margin-top: 20px;
  font-size: 1.5em;
  color: #555;
}
