.login-container {
  background: #f1efe7;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
}

input,
select,
textarea,
button {
  outline: 0;
}

.login-rect {
  position: absolute;
  width: 436px;
  height: 558px;
  top: -15px;
  left: -15px;
  font-size: 0px;
  background: #ffffff;
  z-index: 2;
}

.login-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 170px;
  height: 59px;
  margin: 62px 0 0 131px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
}

.email-input {
  position: relative;
  width: 358px;
  height: 50px;
  margin: 68px 0 0 36px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.password-input {
  position: relative;
  width: 358px;
  height: 50px;
  margin: 20px 0 0 36px;
  background: #f1efe7;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 20px;
  font-weight: 100;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
  overflow: hidden;
}

.login-button {
  position: relative;
  width: 350px;
  height: 50px;
  margin: 60px 0 0 36px;
  cursor: pointer;
  background: #cab3a7;
  border: 1px solid #000000;
  z-index: 3;
  box-sizing: content-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.login-text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  width: 66px;
  height: 50px;
  top: 1px;
  left: 150px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 4;
}

.register-button {
  position: relative;
  width: 350px;
  height: 50px;
  margin: 40px 0 0 36px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #000000;
  z-index: 3;
  box-sizing: content-box;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.register {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  height: 48px;
  top: 0;
  left: 132px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 4;
}

.parent-container {
  display: flex;
  justify-content: center; /* Horizontally centers the .rectangle-6 */
  align-items: center; /* Vertically centers the .rectangle-6 */
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
}

.back-shadow-rect {
  position: relative;
  width: 436px;
  height: 558px;
  background: #c9b3a6;
  z-index: 1;
}
