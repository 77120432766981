input,
select,
textarea,
button {
  outline: 0;
}

.main-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background: #f1efe7;
  overflow: hidden;
  padding: 20px;
}

.block-holder {
  width: auto; /* Ensure width is determined by its content */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure it aligns within the flexbox layout */
}

.flex-column-bf {
  position: absolute;
  width: 600px;
  height: 900px;
  left: -620px;
  top: -400px;
  font-size: 0px;
  z-index: 15;
}

.testimonial-0 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 453px;
  height: 163px;
  margin: 0 0 0 13px;
  color: #000000;
  font-family: Istok Web, var(--default-font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  letter-spacing: 0.1px;
  z-index: 12;
}

.testimonial-1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 406px;
  height: 213px;
  margin: 20px 0 0 183px;
  color: #000000;
  font-family: Istok Web, var(--default-font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  letter-spacing: 0.1px;
  z-index: 15;
}

.testimonial-2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 437px;
  height: 167px;
  margin: 20px 0 0 0;
  color: #000000;
  font-family: Istok Web, var(--default-font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  letter-spacing: 0.1px;
  z-index: 13;
}

.testimonial-3 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  width: 433px;
  height: 155px;
  margin: 20px 0 0 171px;
  color: #000000;
  font-family: Istok Web, var(--default-font-family);
  font-size: 32px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
  letter-spacing: 0.1px;
  z-index: 14;
}

.group {
  position: absolute;
  width: 620px;
  height: 840px;
  left: 0px;
  z-index: 1;
}

.register-foreground-rect {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
  top: -15px;
  left: -15px;
  font-size: 0px;
  background: #ffffff;
  z-index: 1;
}

.register-header {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  width: 222px;
  height: 57px;
  margin: 21px 0 0 198px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 50px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 3;
}

.register-email-input {
    position: relative;
    width: 358px;
    height: 50px;
    margin: 40px 0 0 126px;
    background: #f1efe7;
    color: #000000;
    font-family: Inter, var(--default-font-family);
    font-size: 20px;
    font-weight: 100;
    line-height: 48px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: 0.1px;
    z-index: 3;
    overflow: hidden;
}

.register-username-input {
    position: relative;
    width: 358px;
    height: 50px;
    margin: 10px 0 0 126px;
    background: #f1efe7;
    color: #000000;
    font-family: Inter, var(--default-font-family);
    font-size: 20px;
    font-weight: 100;
    line-height: 48px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: 0.1px;
    z-index: 3;
    overflow: hidden;
}

.register-password-input {
    position: relative;
    width: 358px;
    height: 50px;
    margin: 10px 0 0 126px;
    background: #f1efe7;
    color: #000000;
    font-family: Inter, var(--default-font-family);
    font-size: 20px;
    font-weight: 100;
    line-height: 48px;
    text-align: left;
    white-space: nowrap;
    letter-spacing: 0.1px;
    z-index: 3;
    overflow: hidden;
}
 
.register-register-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 359px;
  height: 50px;
  margin: 57px 0 0 126px;
  cursor: pointer;
  background: #cab3a7;
  border: none;
  z-index: 11;
}
.login {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 66px;
  height: 50px;
  color: #000000;
  font-family: Inter, var(--default-font-family);
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  text-align: center;
  white-space: nowrap;
  letter-spacing: 0.1px;
  z-index: 11;
}

.register-back-shadow-rect {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  width: 612px;
  height: 80%;
  top: 15px;
  left: 15px;
  background: #c9b3a6;
}

.success-message, .error-message {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.success-message, .error-message {
  position: relative;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  z-index: 1; /* Ensure it's above other content */
}

.success-message {
  text-align: center;
  color: White;
  background-color: #30a319; /* Black background */
  padding: 10px; /* Adjust padding */
  font-size: 1rem; /* Adjust font size */
}

.error-message {
  text-align: center;
  color: red;
  background-color: #f8e0e0; /* Light red background */
  padding: 10px; /* Adjust padding */
  font-size: 1rem; /* Adjust font size */
}
